import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FaCcVisa, FaCcMastercard, FaCcAmex, FaCcDinersClub, FaCcDiscover } from 'react-icons/fa';
import { SiPix } from 'react-icons/si';
import ProdutoSugeridoCarrossel from './ProdutoSugeridoCarrossel';
import './Produto.css';

function Produto({ product, onAddToCart, products }) {
  const { productName } = useParams();
  const [mainImage, setMainImage] = useState(product?.imageUrls[0]);
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [showFullDescription, setShowFullDescription] = useState(false);

  useEffect(() => {
    setMainImage(product?.imageUrls[0]);
    setSelectedSizes([]);
    window.scrollTo(0, 0);
  }, [product]);

  if (!product) {
    return <div>Produto não encontrado.</div>;
  }

  const handleThumbnailClick = (url) => {
    setMainImage(url);
  };

  const produto = product;
  const tamanhosDisponiveis = Array.isArray(produto.sizes) ? produto.sizes : [];
  const estoque = typeof produto.stock === 'object' ? produto.stock : {};

  const toggleSizeSelection = (size) => {
    if (selectedSizes.includes(size)) {
      setSelectedSizes(selectedSizes.filter(selected => selected !== size));
    } else {
      if (estoque[size] > 0) {
        setSelectedSizes([...selectedSizes, size]);
      }
    }
  };

  const handleAddToCart = () => {
    if (selectedSizes.length === 0) {
      alert('Por favor, selecione pelo menos um tamanho disponível.');
      return;
    }
    selectedSizes.forEach(size => {
      onAddToCart({ ...produto, selectedSize: size });
    });
    setSelectedSizes([]);
  };

  const randomProducts = products
    .filter((p) => p.id !== produto.id)
    .sort(() => Math.random() - 0.5)
    .slice(0, 8);

  const truncatedDescription =
    produto.description && produto.description.length > 100
      ? `${produto.description.slice(0, 100)}...`
      : produto.description;

  return (
    <div className="produto-container">
      <div className="produto-detalhes">
        <div className="produto-imagens-adicionais">
          {produto.imageUrls.slice(0, 5).map((url, idx) => (
            <img
              key={idx}
              src={url}
              alt={produto.name}
              className={`produto-imagem-pequena ${mainImage === url ? 'active' : ''}`}
              onClick={() => handleThumbnailClick(url)}
            />
          ))}
        </div>
        <div className="produto-imagem-maior">
          <img src={mainImage} alt={produto.name} className="produto-imagem" />
        </div>
        <div className="produto-info">
          <h1>{produto.name}</h1>
          <p className="preco">{`R$ ${produto.price.toFixed(2).replace('.', ',')}`}</p>
          <div className="tamanhos-disponiveis">
            <h3>Tamanhos Disponíveis:</h3>
            <div className="tamanhos">
              {tamanhosDisponiveis.map(size => (
                <span
                  key={size}
                  className={`tamanho ${selectedSizes.includes(size) ? 'selected' : ''} ${estoque[size] <= 0 ? 'indisponivel' : ''}`}
                  onClick={() => toggleSizeSelection(size)}
                >
                  {size}
                </span>
              ))}
            </div>
          </div>
          <button
            className="add-to-cart-btn"
            onClick={handleAddToCart}
            disabled={selectedSizes.length === 0}
          >
            Adicionar ao Carrinho
          </button>

          {produto.description && (
            <div className="produto-descricao">
              <p>
                {showFullDescription
                  ? produto.description
                  : truncatedDescription}
              </p>
              {produto.description.length > 100 && (
                <button
                  className="ver-mais-btn"
                  onClick={() => setShowFullDescription(!showFullDescription)}
                >
                  {showFullDescription ? "Ver menos" : "Ver mais detalhes do produto"}
                </button>
              )}
            </div>
          )}

          <div className="formas-pagamento">
            <p>Formas de pagamento:</p>
            <div className="icones-pagamento">
              <FaCcVisa className="icone-pagamento" />
              <FaCcMastercard className="icone-pagamento" />
              <FaCcAmex className="icone-pagamento" />
              <FaCcDinersClub className="icone-pagamento" />
              <FaCcDiscover className="icone-pagamento" />
              <SiPix className="icone-pagamento" />
            </div>
          </div>
        </div>
      </div>

      <div style={{ marginTop: '40px' }}>
        <ProdutoSugeridoCarrossel products={randomProducts} />
      </div>
    </div>
  );
}

export default Produto;
