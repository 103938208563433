import React, { useState, useEffect, useRef } from 'react';
import { FaSearch } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './Lupa.css';

const normalizeString = (str) => {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^a-zA-Z0-9]/g, '-')
    .toLowerCase();
};

function Lupa({ products }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const searchRef = useRef(null);

  useEffect(() => {
    if (searchTerm.trim() !== '') {
      const results = products.filter((product) =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredProducts(results);
    } else {
      setFilteredProducts([]);
    }
  }, [searchTerm, products]);

  const handleIconClick = () => {
    setIsSearchVisible(!isSearchVisible);
  };

  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setIsSearchVisible(false);
    }
  };

  useEffect(() => {
    if (isSearchVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isSearchVisible]);

  const handleProductSelect = () => {
    setSearchTerm('');
    setIsSearchVisible(false);
  };

  return (
    <div className="lupa-container" ref={searchRef}>
      <FaSearch className="search-icon" onClick={handleIconClick} />
      {isSearchVisible && (
        <div className="search-modal">
          <div className="search-input-wrapper">
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-input"
              placeholder="Busque aqui o seu produto"
              autoFocus
            />
            {filteredProducts.length > 0 && (
              <div className="search-results">
                <ul>
                  {filteredProducts.map((product) => (
                    <li
                      key={product.id}
                      className="search-result-item"
                      onClick={handleProductSelect}
                    >
                      <Link to={`/${normalizeString(product.name)}`} className="search-result-link">
                        <img src={product.imageUrls[0]} alt={product.name} className="result-image" />
                        <span className="result-name">{product.name}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Lupa;
