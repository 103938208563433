import React, { useEffect, useState } from 'react';

function TextoRotativo() {
  const [index, setIndex] = useState(0);
  const [isExiting, setIsExiting] = useState(false);
  const [isEntering, setIsEntering] = useState(true); // Iniciamos com true porque o primeiro texto está entrando
  const mensagens = [
    "5% OFF no PIX à vista, confira!",
    "Site oficial - www.stilofitness.com.br"
  ];

  // Função para trocar as mensagens
  const changeMessage = (newIndex) => {
    setIsExiting(true); // Começa o efeito de saída
    setTimeout(() => {
      setIndex(newIndex);
      setIsExiting(false);
      setIsEntering(true); // Começa o efeito de entrada
      setTimeout(() => setIsEntering(false), 500); // A animação de entrada dura 500ms
    }, 500); // Tempo da animação de saída
  };

  // Troca automática de mensagens a cada 3 segundos
  useEffect(() => {
    const interval = setInterval(() => {
      const newIndex = (index + 1) % mensagens.length;
      changeMessage(newIndex);
    }, 3000); // Troca a cada 3 segundos

    return () => clearInterval(interval); // Limpa o intervalo ao desmontar o componente
  }, [index, mensagens.length]); // Atualiza o efeito sempre que o index mudar

  // Funções para troca manual de mensagens
  const handlePrev = () => {
    const newIndex = (index - 1 + mensagens.length) % mensagens.length;
    changeMessage(newIndex);
  };

  const handleNext = () => {
    const newIndex = (index + 1) % mensagens.length;
    changeMessage(newIndex);
  };

  const formatarMensagem = (mensagem) => {
    if (mensagem.includes('Site oficial')) {
      const partes = mensagem.split(' - ');
      return (
        <span>
          <span className="negrito">{partes[0]}</span> - {partes[1]}
        </span>
      );
    }
    return mensagem;
  };

  return (
    <div className="texto-rotativo">
      <button className="prev-button" onClick={handlePrev}>{'<'}</button>
      <h1 
        className={`mensagem ${isExiting ? 'saida' : ''} ${isEntering ? 'entrada' : ''}`}
      >
        {formatarMensagem(mensagens[index])}
      </h1>
      <button className="next-button" onClick={handleNext}>{'>'}</button>
      <hr className="linha-branca" />
    </div>
  );
}

export default TextoRotativo;
