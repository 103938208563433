import React from "react";
import "./Missao.css";
import missao from "./IMG/missao.png";

const Missao = () => {
  return (
    <div className="missao-container">
      <main className="missao-content">
        <img src={missao} alt="Missão" className="missao-banner" />

        <section className="missao-section">
          <h2>MISSÃO</h2>
          <p>
            Oferecer produtos de qualidade que promovam um estilo de vida saudável, ajudando nossos clientes a alcançarem seus objetivos de bem-estar e performance física. Buscamos inspirar e apoiar cada jornada fitness, fornecendo o melhor vestuário e acessórios fitness.
          </p>
        </section>

        <section className="visao-section">
          <h2>VISÃO</h2>
          <p>
            Ser a principal referência no mercado fitness, reconhecida pela excelência no atendimento, inovação nos produtos e compromisso com o bem-estar dos nossos clientes. Almejamos crescer com sustentabilidade e responsabilidade, contribuindo para uma sociedade mais ativa e saudável.
          </p>
        </section>

        <section className="valores-section">
          <h2>VALORES</h2>
          <ul>
            <li><strong>Saúde e Bem-Estar</strong>: Promovemos um estilo de vida saudável, incentivando práticas que melhorem a qualidade de vida.</li>
            <li><strong>Excelência no Atendimento</strong>: Oferecemos um atendimento personalizado e de alta qualidade, priorizando sempre a satisfação dos nossos clientes.</li>
            <li><strong>Inovação</strong>: Estamos constantemente em busca de novas tendências e produtos que atendam às necessidades do mercado fitness.</li>
            <li><strong>Compromisso com o Cliente</strong>: Valorizamos cada cliente, entendendo suas necessidades e fornecendo soluções adequadas para seus objetivos.</li>
            <li><strong>Ética e Transparência</strong>: Conduzimos nossas ações de forma ética, com transparência e respeito em todas as relações comerciais.</li>
          </ul>
        </section>
      </main>
    </div>
  );
};

export default Missao;
