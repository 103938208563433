// src/firebase.js

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBnysXyK9oBCcFEz7IpISpMnB-fThZJ5Ec",
  authDomain: "stilofitness-e7c96.firebaseapp.com",
  projectId: "stilofitness-e7c96",
  storageBucket: "stilofitness-e7c96.appspot.com",
  messagingSenderId: "7463773150",
  appId: "1:7463773150:web:3356c9a54e07fc0df4f34f",
  measurementId: "G-DKF9HTCY7N"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

export { db, storage };
