import React, { useState } from 'react';
import { db } from './firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import './Pedidos.css';
import { Timestamp } from 'firebase/firestore';

// Função para formatar o telefone
const formatPhone = (phone) => {
  return phone.replace(/\D/g, '').replace(/(\d{2})(\d)/, '($1)$2').replace(/(\d)(\d{4})(\d)/, '$1$2-$3');
};

const Pedidos = () => {
  const [email, setEmail] = useState('');
  const [telephone, setTelephone] = useState('');
  const [pedidos, setPedidos] = useState([]);
  const [message, setMessage] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleTelephoneChange = (e) => {
    // Aplica a formatação ao telefone enquanto digita
    const formattedPhone = formatPhone(e.target.value);
    setTelephone(formattedPhone);
  };

  const fetchPedidos = async () => {
    setMessage('');
    if (!email && !telephone) {
      setMessage('Por favor, insira um e-mail ou telefone.');
      return;
    }

    const pedidosRef = collection(db, 'orders');
    const pedidosQuery = query(
      pedidosRef,
      where('customer.email', '==', email),
      where('customer.telephone', '==', telephone.replace(/\D/g, '')) // Envia apenas os números
    );
    const querySnapshot = await getDocs(pedidosQuery);

    if (querySnapshot.empty) {
      setMessage('Nenhum produto encontrado. Verifique se o email e telefone estão corretos ou entre em contato conosco.');
      setPedidos([]);
    } else {
      const fetchedPedidos = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setPedidos(fetchedPedidos);
    }
  };

  return (
    <div className="pedidos-container">
      <h2>Meus Pedidos</h2>
      <input
        type="email"
        placeholder="Digite seu e-mail cadastrado na compra"
        value={email}
        onChange={handleEmailChange}
      />
      <input
        type="tel"
        placeholder="Digite seu telefone cadastrado na compra"
        value={telephone}
        onChange={handleTelephoneChange}
      />
      <button onClick={fetchPedidos}>Buscar Pedidos</button>
      {message && <p className="message">{message}</p>}
      <div className="pedidos-list">
        {pedidos.map((pedido) => (
          <div key={pedido.id} className="pedido-item">
            <h3>Pedido ID: {pedido.id}</h3>
            <p>Nome: {pedido.customer?.firstname} {pedido.customer?.lastname}</p>
            <p>Email: {pedido.customer?.email}</p>
            <p>Telefone: {pedido.customer?.telephone}</p>
            <p>
              Data: {pedido.timestamp instanceof Timestamp 
                ? new Date(pedido.timestamp.seconds * 1000).toLocaleString('pt-BR')
                : 'Data inválida'}
            </p>
            <h4>Itens:</h4>
            <ul>
              {pedido.orderDetails?.cartItems && pedido.orderDetails.cartItems.length > 0 ? (
                pedido.orderDetails.cartItems.map((item, index) => (
                  <li key={index} className="pedido-item-detail">
                    <span>{item.name} - {item.qty} x R$ {item.price.toFixed(2).replace('.', ',')} (Tamanho: {item.size})</span>
                  </li>
                ))
              ) : (
                <li>Nenhum item encontrado.</li>
              )}
            </ul>
            <p>Total: R$ {pedido.orderDetails?.full_payment_amount?.toFixed(2).replace('.', ',') || 0}</p>
            <p>Forma de Pagamento: {pedido.paymentDetails?.paymentType || 'Não especificado'}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Pedidos;
