import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Produto.css';

function ProdutoSugeridoCarrossel({ products }) {
  const [startIndex, setStartIndex] = useState(0);
  const [itemsToShow, setItemsToShow] = useState(4); // Define 4 por padrão

  // Obtém uma lista aleatória dos produtos excluindo o produto atual
  const randomProducts = [...products].sort(() => Math.random() - 0.5);

  useEffect(() => {
    // Atualiza o número de produtos a serem exibidos com base na largura da tela
    const updateItemsToShow = () => {
      setItemsToShow(window.innerWidth <= 768 ? 2 : 4);
    };

    updateItemsToShow(); // Chama a função inicialmente
    window.addEventListener('resize', updateItemsToShow); // Adiciona listener para resize

    return () => {
      window.removeEventListener('resize', updateItemsToShow); // Limpa o listener
    };
  }, []);

  // Função para avançar o carrossel
  const nextProducts = () => {
    setStartIndex((prevIndex) => (prevIndex + itemsToShow) % randomProducts.length);
  };

  // Função para retroceder o carrossel
  const prevProducts = () => {
    setStartIndex((prevIndex) => 
      (prevIndex - itemsToShow + randomProducts.length) % randomProducts.length
    );
  };

  // Produtos para exibição atual no carrossel
  const displayedProducts = randomProducts.slice(startIndex, startIndex + itemsToShow);

  return (
    <div className="produto-sugerido-container">
      <h2>Você também pode gostar:</h2>
      <div className="carrossel">
        <button className="carrossel-seta" onClick={prevProducts}>
          &#8249;
        </button>
        <div className="carrossel-produtos">
          {displayedProducts.map((product) => (
            <Link
              to={`/${normalizeString(product.name)}`} // Use normalizeString para a rota
              key={product.id}
              className="carrossel-item"
            >
              <img
                src={product.imageUrls[0]}
                alt={product.name}
                className="carrossel-imagem"
              />
              <h3>{product.name}</h3>
              <p className="price">{formatPrice(product.price)}</p>
            </Link>
          ))}
        </div>
        <button className="carrossel-seta" onClick={nextProducts}>
          &#8250;
        </button>
      </div>
    </div>
  );
}

function formatPrice(price) {
  return `R$ ${price.toFixed(2).replace('.', ',')}`;
}

// Certifique-se de que a função normalizeString está disponível neste contexto
function normalizeString(str) {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[^a-zA-Z0-9]/g, "-")
    .toLowerCase();
}

export default ProdutoSugeridoCarrossel;
