import React from "react";
import "./Historia.css";
import historia1 from "./IMG/historia.png";
import historia2 from "./IMG/historia2.png";

const Historia = () => {
  return (
    <div className="historia-container">
      <main className="historia-content">
        <img src={historia1} alt="História 1" className="historia-banner" />

        <section className="luxury-brands">
          <p>
            Celeste Junqueira, fundadora da STILO FITNESS, atuou por mais de 20
            anos como Personal Trainer, e durante sua jornada, percebeu que
            muitas das suas alunas, tinham dificuldades para encontrar produtos
            que oferecessem conforto, qualidade e estilo para seus treinos.
          </p>
          <p>
            Foi a partir daí que surgiu a STILO FITNESS, onde reunimos as melhores
            marcas do mercado fitness, trazendo experiência e know-how durante
            11 anos, para hoje desenvolvermos a marca própria.
          </p>
          <p>
            Em 2024 a STILO FITNESS completou 11 anos de atuação no segmento da
            moda fitness e 1 ano desenvolvendo a marca própria.
          </p>
        </section>

        <img src={historia2} alt="História 2" className="historia-banner" />
     
      </main>
    </div>
  );
};

export default Historia;
